import { Helmet } from 'react-helmet';

import Navbar from '../components/Navbar';
import DynoBody from '../components/DynoBody';
import Footbar from '../components/Footbar';
 
import './App.css';

function Blog() {
  return (
    <div>
      <Helmet>
        <title>{ "ByloByte | Dyno" }</title>
        <meta name='description' content= 'Bulk Discount Code Generator for Shopify Merchants. Generate and export custom discount codes in bulk. Boost sales and customer engagement when running campaigns.'/>
      </Helmet>

      <Navbar/>
      <DynoBody />
      <Footbar />
    </div>
  );
}

export default Blog;
