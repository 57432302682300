const ContactBody = () => {

  return (
    <div className=''>
      <div className="container center-max-width mt-5">
          <div className='container card-box p-4'>
            <div>
              <h2 variant="headingXl" as="h4">
                Contact us
              </h2>
              <p>
                Hello there! We're delighted to be your go-to contacts for any questions, 
                concerns, or just a friendly chat. At Verumus, we understand that 
                life doesn't always stick to a 9-to-5 schedule, so neither do we! Our team is 
                here for you 24/7, ready to assist and ensure your experience with us is as 
                smooth as possible. Whether you prefer a call, email, or reaching out through 
                our socials, we're just a message away. Your satisfaction is our top priority, 
                and we can't wait to connect with you whenever you need us. Cheers to building 
                a fantastic relationship together!
              </p>
              <div alignItems="center" columns={3}>
                  <ul type="bullet">
                    <li><a href="https://wa.me/+15513732595" target="_blank">Whatsapp</a></li>
                    <li><a href="https://t.me/verumus" target="_blank">Telegram</a></li>
                    <li>Phone number: <a href="tel:+15513732595" target="_blank">+1 (551) 373-2595</a></li>
                    <li>E-mail: <a href="mailto:info@bylobyte.com" target="_blank">info@bylobyte.com</a></li>
                  </ul>
              </div>
            </div>
          </div>

          <div className='container card-box mt-5 p-4'>
            <div>
              <h2 variant="headingXl" as="h4">
                Privacy
              </h2>
              <p>
                Last updated: 01/28/2024
              </p>
              <p>
                Verumus ("us", "we", or "our") operates the 
                Bulk Discount Code Gen app (the "Service") on the Shopify platform.
                This page informs you of our policies regarding the collection,
                use, and disclosure of Personal Information when you use our Service.
              </p>
              <p>
                We do not use or share your information with anyone except as described 
                in this Privacy Policy.
              </p>
              <h2 variant="headingMd" as="h6">
                Information Collection and Use
              </h2>
              <p>
              While using our Service, we collect and store the following data:
              </p>
              <ul type="bullet">
                <li>Shopify store name,</li>
                <li>Title of the discount created,</li>
                <li>Number of codes generated,</li>
                <li>Creation date of the discount.</li>
              </ul>
              <p>
              Access scopes needed:
              </p>
              <ul type="bullet">
                <li>read_products: to associate discounts with products and collections,</li>
                <li>read_discounts: to generate discount codes for a spesific discount,</li>
                <li>write_discounts: to create discounts.</li>
              </ul>
              <h2 variant="headingMd" as="h6">
                Log Data
              </h2>
              <p>
                We collect information that your browser sends whenever you use our Service 
                ("Log Data"). This Log Data may include information such as your computer's 
                Internet Protocol ("IP") address, browser type, browser version, the pages of
                 our Service that you visit, the time and date of your visit, the time spent 
                 on those pages, and other statistics.
              </p>
              <h2 variant="headingMd" as="h6">
                Cookies
              </h2>
              <p>
                Cookies are files with a small amount of data, which may include an anonymous unique 
                identifier. Cookies are sent to your browser from a website and stored on your 
                computer's hard drive.
              </p>
              <p>
                We use "cookies" to collect information. You can instruct your browser to refuse all 
                cookies or to indicate when a cookie is being sent. However, if you do not accept cookies,
                you may not be able to use some portions of our Service.
              </p>
              <h2 variant="headingMd" as="h6">
                Security
              </h2>
              <p>
                The security of your Personal Information is important to us, but remember that no 
                method of transmission over the Internet or method of electronic storage is 100% secure.
                 While we strive to use commercially acceptable means to protect your Personal Information,
                  we cannot guarantee its absolute security.
              </p>
              <h2 variant="headingMd" as="h6">
                Changes to This Privacy Policy
              </h2>
              <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes 
                by posting the new Privacy Policy on this page.
              </p>
            </div>
          </div>
        </div>
    </div>
  );
}

export default ContactBody;
