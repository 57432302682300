import Lottie from 'react-lottie';

import { useNavigate } from 'react-router-dom'; // Import useHistory from react-router-dom

import blogImage1 from "../images/blog-images/blog-image-1.jpg"
import blogImage2 from "../images/blog-images/blog-image-2.jpg"
import blogImage3 from "../images/blog-images/blog-image-3.jpg"
import blogImage4 from "../images/blog-images/blog-image-4.jpg"
import blogImage5 from "../images/blog-images/blog-image-5.jpg"
import blogImage6 from "../images/blog-images/blog-image-6.jpg"
import blogImage7 from "../images/blog-images/blog-image-7.jpg"

import library from '../images/animations/library.json';

const BlogBody = () => {
  const navigate = useNavigate()

  const libraryAnimation = {
    loop: true,
    autoplay: true,
    animationData: library,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };

  return (
    <div className=''>
			<div class="col-xxl-8 center-max-width mx-auto my-5">				
				<div class="row flex-lg-row-reverse align-items-center g-5 d-flex justify-content-center center-max-width">
					<div class="col-10 col-sm-8 col-lg-6" style={{ pointerEvents: 'none' }}>
						<Lottie options={libraryAnimation} />
					</div>
					<div class="col-lg-6">
						<h4 class="display-6 fw-bold text-body-emphasis lh-1 mb-3">Welcome to Our Blog</h4>
						<p class="lead">Step into a world of inspiration, knowledge, and creativity as we embark on a captivating journey through insightful blog posts that ignite your curiosity and fuel your passion.</p>
					</div>
				</div>
      </div>

      <div className="center-max-width">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 center-max-width">
          <div className="col">
            <div className="blog-card mx-auto" onClick={() => (navigate('/5-essential-tips-maximizing-sales-in-shopify'))}>
              <img className='blog-image' src={blogImage7} alt=""/>
              <div className='blog-title m-auto'>
                <p className='m-2 fw-light'>March 21, 2024</p>
                <p className='m-2 fw-medium'>5 Essential Tips for Maximizing Sales on Shopify</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="blog-card mx-auto" onClick={() => (navigate('/unique-discount-codes'))}>
              <img className='blog-image' src={blogImage1} alt=""/>
              <div className='blog-title m-auto'>
                <p className='m-2 fw-light'>March 14, 2024</p>
                <p className='m-2 fw-medium'>Maximize Your Sales: How Unique Discount Codes Can Boost Your Shopify Store</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="blog-card mx-auto" onClick={() => (navigate('/psychology-of-discounts'))}>
              <img className='blog-image' src={blogImage2} alt=""/>
              <div className='blog-title m-auto'>
                <p className='m-2 fw-light'>March 11, 2024</p>
                <p className='m-2 fw-medium'>The Psychology of Discounts: How to Effectively Use Discount Codes to Drive Sales</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="blog-card mx-auto" onClick={() => (navigate('/creating-a-sense-of-urgency'))}>
              <img className='blog-image' src={blogImage3} alt=""/>
              <div className='blog-title m-auto'>
                <p className='m-2 fw-light'>February 20, 2024</p>
                <p className='m-2 fw-medium'>Creating a Sense of Urgency: Time-Limited Discount Codes on Shopify</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="blog-card mx-auto" onClick={() => (navigate('/discount-code-etiquette'))}>
              <img className='blog-image' src={blogImage4} alt=""/>
              <div className='blog-title m-auto'>
                <p className='m-2 fw-light'>January 13, 2024</p>
                <p className='m-2 fw-medium'>Discount Code Etiquette: Best Practices for Offering Discounts Without Devaluing Your Products</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="blog-card mx-auto" onClick={() => (navigate('/loyalty-rewards-programs'))}>
              <img className='blog-image' src={blogImage5} alt=""/>
              <div className='blog-title m-auto'>
                <p className='m-2 fw-light'>January 08, 2024</p>
                <p className='m-2 fw-medium'>Loyalty Rewards Programs: Using Discount Codes to Retain Shopify Customers</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="blog-card mx-auto" onClick={() => (navigate('/tracking-roi-impact-of-discount-codes'))}>
              <img className='blog-image' src={blogImage6} alt=""/>
              <div className='blog-title m-auto'>
                <p className='m-2 fw-light'>December 02, 2023</p>
                <p className='m-2 fw-medium'>Tracking ROI: Measuring the Impact of Discount Codes on Shopify Store Performance</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogBody;
