import bylobyteLogo from '../images/logos/bylobyte_logo.png'

const Navbar = () => {
  return (
		<nav class="navbar navbar-expand-sm" aria-label="Third navbar example">
			<div class="container-fluid center-max-width">
				<a class="navbar-brand d-flex align-items-center" href="/">
					<img src={bylobyteLogo} style={{width: "35px", height:'35px'}} alt="Dyno: Bulk Discount Codes Logo" width="30" height="30" className="d-inline-block align-middle mx-2"></img>
					<span class="fw-medium fs-3">ByloByte</span>
				</a>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>

				<div class="collapse navbar-collapse" id="navbarsExample03">
					<ul class="navbar-nav me-auto mb-2 mb-sm-0">
						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Products</a>
							<ul class="dropdown-menu">
								<li><a class="dropdown-item" href="/dyno-bulk-discount-codes">Dyno: Bulk Discount Codes</a></li>
								{/* <li><a class="dropdown-item" href="/volt-low-stock-alerts">Volt: Low Stock Alerts</a></li> */}
							</ul>
						</li>
						<li class="nav-item">
							<a class="nav-link" aria-current="page" href="/blog">Blog</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="/contact">Contact</a>
						</li>
					</ul>
					<form role="search">
						<input class="form-control" type="search" placeholder="Search" aria-label="Search"></input>
					</form>
				</div>
			</div>
		</nav>
  );
}

export default Navbar;
