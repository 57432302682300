import bylobyteLogo from '../images/logos/bylobyte_logo.png'

const Footbar = () => {
  return (
		<div class="container mt-5 center-max-width">
			<footer class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 border-top">
				<div class="col mb-3">
					<a href="/" class="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none">
						<img src={bylobyteLogo} alt="Dyno: Bulk Discount Codes Logo" width="30" height="30" className="d-inline-block align-text-bottom mx-2"></img>
					</a>
					<p class="text-body-secondary">ByloByte © 2024</p>
				</div>

				<div class="col mb-3">

				</div>

				<div class="col mb-3">
					<h5>Products</h5>
					<ul class="nav flex-column">
						{/* <li class="nav-item mb-2"><a href="/volt-low-stock-alerts" class="nav-link p-0 text-body-secondary">Volt: Low Stock Alerts</a></li> */}
						<li class="nav-item mb-2"><a href="/dyno-bulk-discount-codes" class="nav-link p-0 text-body-secondary">Dyno: Bulk Discount Codes</a></li>
					</ul>
				</div>

				<div class="col mb-3">
					<h5>ByloByte</h5>
					<ul class="nav flex-column">
						<li class="nav-item mb-2"><a href="/blog" class="nav-link p-0 text-body-secondary">Blog</a></li>
						{/* <li class="nav-item mb-2"><a href="/write-for-us" class="nav-link p-0 text-body-secondary">Write for us</a></li> */}
					</ul>
				</div>

				<div class="col mb-3">
					<h5>Learn More</h5>
					<ul class="nav flex-column">
						{/* <li class="nav-item mb-2"><a href="/about" class="nav-link p-0 text-body-secondary">About</a></li> */}
						<li class="nav-item mb-2"><a href="/contact" class="nav-link p-0 text-body-secondary">Contact</a></li>
						{/* <li class="nav-item mb-2"><a href="/faqs" class="nav-link p-0 text-body-secondary">FAQs</a></li> */}
					</ul>
				</div>
			</footer>
		</div>
  );
}

export default Footbar;
