import { Helmet } from 'react-helmet';

import Navbar from '../components/Navbar';
import WriteForUsBody from '../components/WriteForUsBody';
import Footbar from '../components/Footbar';
 
import './App.css';

function WriteForUs() {
  return (
    <div>
      <Helmet>
        <title>{ "ByloByte | Write For Us" }</title>
        <meta name='description' content= "Explore opportunities to contribute to ByloByte's content. Learn about how you can share your expertise with our audience."/>
      </Helmet>

      <Navbar/>
      <WriteForUsBody />
      <Footbar />
    </div>
  );
}

export default WriteForUs;
