import { Helmet } from 'react-helmet';

import Navbar from '../components/Navbar';
import ContactBody from '../components/ContactBody';
import Footbar from '../components/Footbar';
 
import './App.css';

function App() {
  return (
    <div>
      <Helmet>
        <title>{ "ByloByte | Contact" }</title>
        <meta name='description' content= 'Contact ByloByte for inquiries, collaborations, or any questions you may have. Our team is ready to assist you with your needs and provide personalized support.'/>
      </Helmet>

      <Navbar/>
      <ContactBody />
      <Footbar />
    </div>
  );
}

export default App;
