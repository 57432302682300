import { Helmet } from 'react-helmet';

import Navbar from '../components/Navbar';
import BlogBody from '../components/BlogBody';
import Footbar from '../components/Footbar';
 
import './App.css';

function Blog() {
  return (
    <div>
      <Helmet>
        <title>{ "ByloByte | Blog" }</title>
        <meta name='description' content= 'Step into a world of inspiration, knowledge, and creativity as we embark on a captivating journey through insightful blog posts.'/>
      </Helmet>

      <Navbar/>
      <BlogBody />
      <Footbar />
    </div>
  );
}

export default Blog;
