import Navbar from '../components/Navbar';
import MainBody from '../components/MainBody';
import Footbar from '../components/Footbar';
 
import './App.css';

function Mainpage() {
  return (
    <div>
      <Navbar/>
      <MainBody />
      <Footbar />
    </div>
  );
}

export default Mainpage;
