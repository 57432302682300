import { Helmet } from 'react-helmet';

import Navbar from '../components/Navbar';
import DynoPrivacyBody from '../components/DynoPrivacyBody';
import Footbar from '../components/Footbar';
 
import './App.css';

function DynoPrivacy() {
  return (
    <div>
      <Helmet>
        <title>{ "ByloByte | Dyno | Privacy" }</title>
        <meta name='description' content= 'Learn about our privacy policy at ByloByte | Dyno. We are committed to protecting your data in how we collect, use, and secure your information.'/>
      </Helmet>

      <Navbar/>
      <DynoPrivacyBody />
      <Footbar />
    </div>
  );
}

export default DynoPrivacy;
