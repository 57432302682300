import Lottie from 'react-lottie';
import Slider from 'react-infinite-logo-slider'

import { useNavigate } from 'react-router-dom'; // Import useHistory from react-router-dom


import creatingApplication from '../images/animations/creating-appication.json';
import contactUs from '../images/animations/contact-us.json';
import design from '../images/animations/design.json';
import money from '../images/animations/money.json';
import shield from '../images/animations/shield.json';
import integration from '../images/animations/integration.json';


import logo_1 from "../images/company_logos/logo-1.svg"
import logo_2 from "../images/company_logos/logo-2.svg"
import logo_3 from "../images/company_logos/logo-3.svg"
import logo_4 from "../images/company_logos/logo-4.svg"
import logo_5 from "../images/company_logos/logo-5.svg"
import logo_6 from "../images/company_logos/logo-6.svg"
import logo_7 from "../images/company_logos/logo-7.svg"
import logo_8 from "../images/company_logos/logo-8.svg"
import logo_9 from "../images/company_logos/logo-9.svg"
import logo_11 from "../images/company_logos/logo-11.svg"
import logo_12 from "../images/company_logos/logo-12.svg"
import logo_13 from "../images/company_logos/logo-13.svg"
import logo_14 from "../images/company_logos/logo-14.svg"
import logo_15 from "../images/company_logos/logo-15.svg"
import logo_16 from "../images/company_logos/logo-16.svg"
import logo_17 from "../images/company_logos/logo-17.svg"
import logo_18 from "../images/company_logos/logo-18.svg"
import logo_19 from "../images/company_logos/logo-19.svg"
import logo_10 from "../images/company_logos/logo-10.svg"

const Hero = () => {
	const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: creatingApplication,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };
	const moneyIcon = {
    loop: true,
    autoplay: true,
    animationData: money,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };
	const designIcon = {
    loop: true,
    autoplay: true,
    animationData: design,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };
	const shieldIcon = {
    loop: true,
    autoplay: true,
    animationData: shield,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };
	const contactUsAnimation = {
    loop: true,
    autoplay: true,
    animationData: contactUs,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };
	const integrationAnimation = {
    loop: true,
    autoplay: true,
    animationData: integration,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };

  return (
		<div>
			<div class="container col-xxl-8 py-5 center-max-width">
				<div class="row flex-lg-row-reverse align-items-center g-5 py-5 d-flex justify-content-center center-max-width">
					<div class="col-10 col-sm-8 col-lg-6" style={{ pointerEvents: 'none' }}>
						<Lottie options={defaultOptions} />
					</div>
					<div class="col-lg-6">
						<h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">Boost Sales with<br/>Shopify Apps</h1>
						<p class="lead">Our tailored apps optimize operations and drive sales, helping you succeed in the competitive eCommerce landscape. Elevate your store today!</p>
						<div class="d-grid gap-2 d-md-flex justify-content-md-start">
							<button type="button" class="btn btn-outline-secondary px-4" onClick={() => (window.open("https://apps.shopify.com/partners/bylobyte"))}>
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<svg className="shopify-svg align-middle me-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M388.3 104.1a4.7 4.7 0 0 0 -4.4-4c-2 0-37.2-.8-37.2-.8s-21.6-20.8-29.6-28.8V503.2L442.8 472S388.7 106.5 388.3 104.1zM288.7 70.5a116.7 116.7 0 0 0 -7.2-17.6C271 32.9 255.4 22 237 22a15 15 0 0 0 -4 .4c-.4-.8-1.2-1.2-1.6-2C223.4 11.6 213 7.6 200.6 8c-24 .8-48 18-67.3 48.8-13.6 21.6-24 48.8-26.8 70.1-27.6 8.4-46.8 14.4-47.2 14.8-14 4.4-14.4 4.8-16 18-1.2 10-38 291.8-38 291.8L307.9 504V65.7a41.7 41.7 0 0 0 -4.4 .4S297.9 67.7 288.7 70.5zM233.4 87.7c-16 4.8-33.6 10.4-50.8 15.6 4.8-18.8 14.4-37.6 25.6-50 4.4-4.4 10.4-9.6 17.2-12.8C232.2 54.9 233.8 74.5 233.4 87.7zM200.6 24.4A27.5 27.5 0 0 1 215 28c-6.4 3.2-12.8 8.4-18.8 14.4-15.2 16.4-26.8 42-31.6 66.5-14.4 4.4-28.8 8.8-42 12.8C131.3 83.3 163.8 25.2 200.6 24.4zM154.2 244.6c1.6 25.6 69.3 31.2 73.3 91.7 2.8 47.6-25.2 80.1-65.7 82.5-48.8 3.2-75.7-25.6-75.7-25.6l10.4-44s26.8 20.4 48.4 18.8c14-.8 19.2-12.4 18.8-20.4-2-33.6-57.2-31.6-60.8-86.9-3.2-46.4 27.2-93.3 94.5-97.7 26-1.6 39.2 4.8 39.2 4.8L221.4 225.4s-17.2-8-37.6-6.4C154.2 221 153.8 239.8 154.2 244.6zM249.4 82.9c0-12-1.6-29.2-7.2-43.6 18.4 3.6 27.2 24 31.2 36.4Q262.6 78.7 249.4 82.9z"/></svg>
									</div>
									<div style={{ fontSize: '20px' }}>Shopify App Store</div>
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div class="container py-5 center-max-width" id="featured-3">
				<div class="pb-2 border-bottom"></div>
				<div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
					<div class="feature col">
						<div class="d-inline-flex align-items-center justify-content-center fs-2 mb-3" style={{ width: '100px', pointerEvents: 'none' }}>
						<Lottie options={designIcon} />
						</div>
						<h3 class="fs-2 text-body-emphasis">Personalized Solutions</h3>
						<p>Tailored apps customized to your specific business needs, ensuring seamless integration and maximum efficiency.</p>
					</div>
					<div class="feature col">
						<div class="d-inline-flex align-items-center justify-content-center fs-2 mb-3" style={{ width: '100px', pointerEvents: 'none' }}>
						<Lottie options={moneyIcon} />
						</div>
						<h3 class="fs-2 text-body-emphasis">Sales Booster</h3>
						<p>Innovative tools and strategies to drive sales, increase conversion rates, and maximize revenue for your Shopify store.</p>

					</div>
					<div class="feature col">
						<div class="d-inline-flex align-items-center justify-content-center fs-2 mb-3" style={{ width: '100px', pointerEvents: 'none' }}>
						<Lottie options={shieldIcon} />
						</div>
						<h3 class="fs-2 text-body-emphasis">Robust Security</h3>
						<p>Advanced security protocols and encryption measures to safeguard your data and protect your customers' information.</p>
					</div>
				</div>
			</div>

			<div class="container px-4 py-5 center-max-width border-bottom border-top">
				<Slider
          width="250px"
          duration={90}
          pauseOnHover={false}
          blurBorders={false}
          blurBoderColor={'#fff'}
        >
          <Slider.Slide>
					<img className='company-logo' src={logo_1} alt="company logo"></img>
					</Slider.Slide>
          <Slider.Slide>
					<img className='company-logo' src={logo_2} alt="company logo"></img>
					</Slider.Slide>
          <Slider.Slide>
					<img className='company-logo' src={logo_3} alt="company logo"></img>
					</Slider.Slide>
          <Slider.Slide>
					<img className='company-logo' src={logo_4} alt="company logo"></img>
          </Slider.Slide>          
					<Slider.Slide>
					<img className='company-logo' src={logo_5} alt="company logo"></img>
					</Slider.Slide>
          <Slider.Slide>
					<img className='company-logo' src={logo_6} alt="company logo"></img>
					</Slider.Slide>
          <Slider.Slide>
					<img className='company-logo' src={logo_7} alt="company logo"></img>
					</Slider.Slide>
          <Slider.Slide>
					<img className='company-logo' src={logo_8} alt="company logo"></img>
          </Slider.Slide>
					<Slider.Slide>
					<img className='company-logo' src={logo_9} alt="company logo"></img>
					</Slider.Slide>
          <Slider.Slide>
					<img className='company-logo' src={logo_10} alt="company logo"></img>
					</Slider.Slide>
          <Slider.Slide>
					<img className='company-logo' src={logo_11} alt="company logo"></img>
					</Slider.Slide>
          <Slider.Slide>
					<img className='company-logo' src={logo_12} alt="company logo"></img>
          </Slider.Slide>
					<Slider.Slide>
					<img className='company-logo' src={logo_13} alt="company logo"></img>
          </Slider.Slide>
					<Slider.Slide>
					<img className='company-logo' src={logo_14} alt="company logo"></img>
          </Slider.Slide>
					<Slider.Slide>
					<img className='company-logo' src={logo_15} alt="company logo"></img>
          </Slider.Slide>
					<Slider.Slide>
					<img className='company-logo' src={logo_16} alt="company logo"></img>
          </Slider.Slide>
					<Slider.Slide>
					<img className='company-logo' src={logo_17} alt="company logo"></img>
          </Slider.Slide>
					<Slider.Slide>
					<img className='company-logo' src={logo_18} alt="company logo"></img>
          </Slider.Slide>
					<Slider.Slide>
					<img className='company-logo' src={logo_19} alt="company logo"></img>
          </Slider.Slide>
        </Slider>
			</div>

			<div class="container col-xxl-8 center-max-width my-5">				
				<div class="row flex-lg-row-reverse align-items-center g-5 d-flex justify-content-center center-max-width">
					<div class="col-10 col-sm-8 col-lg-6" style={{ pointerEvents: 'none' }}>
						<Lottie options={integrationAnimation} />
					</div>
					<div class="col-lg-6">
						<h4 class="display-6 fw-bold text-body-emphasis lh-1 mb-3">* Seamless Integration</h4>
						<p class="lead">Our apps integrate seamlessly with your Shopify admin, simplifying operations and boosting productivity. Manage everything within one user-friendly dashboard.</p>
					</div>
				</div>
				<div class="row flex-lg-row align-items-center g-5 d-flex justify-content-center center-max-width">
					<div class="col-10 col-sm-8 col-lg-6" style={{ pointerEvents: 'none' }}>
						<Lottie options={contactUsAnimation} />
					</div>
					<div class="col-lg-6">
						<h4 class="display-6 fw-bold text-body-emphasis lh-1 mb-3">* Expert Support</h4>
						<p class="lead">Our team of experts provides 24/7 live support, ensuring that any issues or questions you have are promptly addressed, keeping your store running smoothly.</p>
					</div>
				</div>
			</div>
		</div>
  );
}

export default Hero;
