import { useForm, ValidationError } from '@formspree/react';

const ContactCard = () => {
	const [state, handleSubmit] = useForm("mjvnljvj");
  if (state.succeeded) {
      return <p>Your message is received! Please allow us 2 business days to respond.</p>;
  }

  return (
    <form onSubmit={handleSubmit}>
			<label htmlFor="email">
					Email Address
			</label>
			<div class="form-group">
				<input
					id="email"
					type="email" 
					name="email"
					class="form-control" 
				/>
				<ValidationError 
					prefix="Email" 
					field="email"
					errors={state.errors}
				/>
			</div>
			<label htmlFor="email" className=' mt-4'>
					Message
			</label>
			<div class="form-group">
				<textarea
					id="message"
					name="message"
					class="form-control" 
					rows="7"
				/>
				<ValidationError 
					prefix="Message" 
					field="message"
					errors={state.errors}
				/>
			</div>
      <button class="btn btn-outline-secondary px-4 mt-4" type="submit" disabled={state.submitting}>
        Submit
      </button>
    </form>
  );
}

export default ContactCard;