import Lottie from 'react-lottie';

import support from '../images/animations/customer-support.json';
import ContactCard from './ContactCard'

const ContactBody = () => {
  const supportAnimation = {
    loop: true,
    autoplay: true,
    animationData: support,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };

  return (
    <div className=''>
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row align-items-center g-5 py-5 d-flex justify-content-center">
          <div className="col-10 col-sm-8 col-lg-6" style={{ pointerEvents: 'none' }}>
            <Lottie options={supportAnimation} />
          </div>
          <div className="col-lg-6">
						<ContactCard />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactBody;
